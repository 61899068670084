<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">Consider the following chemical equilibrium:</p>

      <p class="mb-5 pl-8">
        <chemical-latex content="2A(g) + B(s) <=> 2 C(s) + D(g)" />
      </p>

      <p class="mb-2">
        If the initial pressure of A is
        <number-value :value="PAi" unit="\text{bar,}" />
        the total pressure at equilibrium ends up being
        <number-value :value="Ptot" unit="\text{bar.}" />
        Use this information to determine the value of the equilibrium constant
        <stemble-latex content="$\text{K}_\text{eq.}$" />
      </p>

      <calculation-input
        v-model="inputs.Keq"
        class="mb-0"
        prepend-text="$\text{K}_\text{eq}:$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalReactionInput from '@/tasks/components/inputs/ChemicalReactionInput.vue';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';
import NumberValue from '@/tasks/components/NumberValue.vue';
import LatexNumber from '@/tasks/components/displayers/LatexNumber.vue';

export default {
  name: 'Question463',
  components: {
    ChemicalNotationInput,
    NumberValue,
    CalculationInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        Keq: null,
      },
    };
  },
  computed: {
    PAi() {
      return this.taskState.getValueBySymbol('PAi').content;
    },
    Ptot() {
      return this.taskState.getValueBySymbol('Ptot').content;
    },
  },
};
</script>
